import React from 'react';

function About() {
    return (
        <div>
            <h1>About the karaf react demo</h1>

            <p>This is a testbed for delivering <a href="https://reactjs.org">react.js</a> frontends from apache karaf.</p>
        </div>
    );
}

export default About;
